import React from "react";
import styled from "styled-components";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useMatchMedia } from "../../hooks/use-match-media";
import { createTimeline, gsapClearProps } from "../../utilities/animation-helper";

gsap.registerPlugin(ScrollTrigger);

interface WrapperProps {
  modifier?: string;
}

interface TraingleProps {
  left?: boolean;
}

export interface QuoteProps {
  message: string | React.ReactNode;
  name: string;
  position: string;
  left?: boolean;
  modifier: string;
  place?: "homepage" | "story" | "work";
  hideArrow?: boolean;
}

const QuoteElement: React.FC<QuoteProps> = ({ message, name, position, modifier, left, place, hideArrow = false }) => {
  const quoteDotWrapper = React.useRef<HTMLDivElement>(null);
  const quoteMessageWrapper = React.useRef<HTMLDivElement>(null);
  const contentQuoteWrapper = React.useRef<HTMLDivElement>(null);
  const triangleWrapper = React.useRef<HTMLDivElement>(null);
  const tl = React.useRef<GSAPTimeline>();
  const mobileTl: gsap.TimelineVars = React.useRef<GSAPTimeline>();
  const isMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    gsapClearProps([
      quoteDotWrapper.current,
      quoteMessageWrapper.current,
      contentQuoteWrapper.current,
      triangleWrapper.current,
      quoteDotWrapper.current?.children,
      contentQuoteWrapper.current?.children,
      contentQuoteWrapper.current?.children[0],
      contentQuoteWrapper.current?.children[1],
    ]);

    const transformOriginWrapper = left || isMobile ? "bottom left" : "bottom right";
    const transformTriangleWrapper = left || isMobile ? "top left" : "top right";

    const startAnimation = isMobile
      ? "-=300 center"
      : place === "homepage"
      ? "-=550 center"
      : place === "work"
      ? "top-=800% center"
      : "top-=1400% center";

    mobileTl.current = createTimeline({
      paused: !isMobile,
    });

    const timeoutQuote = setTimeout(() => {
      tl.current = createTimeline({
        scrollTrigger: {
          trigger: quoteDotWrapper.current,
          start: startAnimation,
        },
      });

      gsap.set(
        [
          quoteDotWrapper.current,
          quoteDotWrapper.current?.children,
          quoteMessageWrapper.current,
          contentQuoteWrapper.current,
          contentQuoteWrapper.current?.children[0],
          contentQuoteWrapper.current?.children[1],
          triangleWrapper.current,
        ],
        {
          autoAlpha: 0,
        }
      );
      gsap.set(triangleWrapper.current, {
        scale: 0,
        transformOrigin: transformTriangleWrapper,
      });
      gsap.set(quoteMessageWrapper.current, {
        visibility: "visible",
        autoAlpha: 1,
      });
      gsap.set(contentQuoteWrapper.current, {
        scale: 0,
        autoAlpha: 1,
        transformOrigin: transformOriginWrapper,
      });
      mobileTl.current
        .to(quoteDotWrapper.current, { autoAlpha: 1, duration: 0.2 })
        .to([quoteDotWrapper.current?.children], {
          autoAlpha: 1,
          background: "black",
          duration: 0.2,
          stagger: 0.2,
        })
        .to(
          [quoteDotWrapper.current?.children],
          {
            autoAlpha: 1,
            background: "#6B6B6B",
            duration: 0.2,
            stagger: 0.2,
          },
          "-=.2"
        )
        .to(
          [quoteDotWrapper.current?.children],
          {
            autoAlpha: 1,
            background: "transparent",
            duration: 0.2,
            stagger: 0.2,
          },
          "-=.2"
        )
        .to(quoteDotWrapper.current, {
          autoAlpha: 0,
          display: "none",
          duration: 0.3,
        });

      tl.current
        .add(mobileTl.current)
        .set(contentQuoteWrapper.current, {
          visibility: "visible",
        })
        .to([contentQuoteWrapper.current], {
          scale: 1,
          duration: 0.5,
        })
        .to(
          triangleWrapper.current,
          {
            scale: 1,
            autoAlpha: 1,
            duration: 0.5,
          },
          "-=.5"
        )
        .fromTo(
          [contentQuoteWrapper.current?.children[0], contentQuoteWrapper.current?.children[1]],
          { autoAlpha: 0, y: 20 },
          { autoAlpha: 1, y: 0, duration: 0.5 }
        );
    }, 1);

    return () => {
      tl?.current?.kill();
      clearTimeout(timeoutQuote);
    };
  }, [isMobile]);

  return (
    <>
      <QuoteWrapper ref={quoteDotWrapper} modifier={modifier}>
        <Dot />
        <Dot />
        <Dot />
      </QuoteWrapper>
      <Wrapper modifier={modifier} ref={quoteMessageWrapper}>
        <AllQuoteWrapper>
          <DivContentWrapper ref={contentQuoteWrapper}>
            <ContentWrapper>{message}</ContentWrapper>
            <SubContent>
              <strong>{name}</strong> <br />
              {position}
            </SubContent>
          </DivContentWrapper>
          {!hideArrow && <TriangleWrapper left={left} ref={triangleWrapper} />}
        </AllQuoteWrapper>
      </Wrapper>
    </>
  );
};

export default QuoteElement;

const QuoteWrapper = styled.div.attrs<WrapperProps>(({ modifier }) => ({
  className: `w-24 h-9 absolute invisible ${
    modifier ? modifier : ""
  } bg-[white]/60 backdrop-blur-md rounded-lg flex items-center justify-evenly px-2.5 z-20`,
}))<WrapperProps>``;
const Dot = styled.div.attrs({
  className: "w-2.5 h-2.5 rounded-full bg-transparetn inline-block",
})``;
const Wrapper = styled.div.attrs<WrapperProps>(({ modifier }) => ({
  className: `absolute invisible ${modifier ? modifier : ""}  z-20`,
}))<WrapperProps>``;
const DivContentWrapper = styled.div.attrs({
  className:
    "bg-[white]/60 z-20 p-6 flex flex-col rounded-[1.25rem] backdrop-blur-md w-64 tablet:w-[17rem] outline-none border-0 ",
})`
  @supports (-moz-appearance: none) {
    background-color: rgba(208, 208, 208, 0.95);
  }
`;
const AllQuoteWrapper = styled.div.attrs({ className: "" })``;
const TriangleWrapper = styled.div.attrs<TraingleProps>(({ left }) => ({
  className: ` ${left ? "ml-5" : "ml-5 tablet:ml-52"}  w-0 h-0 border-t-[1rem] ${
    left
      ? "border-l-[0px] border-r-[2rem]"
      : "border-l-[0px] border-r-[2rem] tablet:border-l-[2rem] tablet:border-r-[0px]"
  }  border-t-[white]/60 rounded-b-md border-l-transparent border-r-transparent z-20`,
}))<TraingleProps>`
  @supports (-moz-appearance: none) {
    border-top-color: rgba(208, 208, 208, 0.95);
  }
`;
const ContentWrapper = styled.p.attrs({
  className: "font-bold leading-5 text-base tablet:text-paragraph text-gray mb-4",
})``;
const SubContent = styled.p.attrs({
  className: "font-normal text-sm text-gray",
})``;
