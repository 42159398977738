import React from "react";
import styled from "styled-components";
import QuoteElement from "../../components/quote-element/quote-element";
import IcoBox from "../../components/icon/IcoBox";
import { ImageLeftWrapper, ImageRightWrapper } from "../../components/images/images";
import { Headline2, Paragraph } from "../../components/typography";
import { theme } from "../../styles/styles";
import { getElementImage, getElementText, getElementVideo } from "../../utilities/pimcore-data-transformer";
import { PimcoreImage } from "../../components/pimcore-image";
import { PageElement } from "../../graphql/page-common";
import { useMatchMedia } from "../../hooks/use-match-media";
import { onScrollAnimationDesktop, returnRefArray } from "../../utilities/animation-helper";
import { BulbIcon, IconInterface, OvenIcon, SleepIcon, SmartFingerIcon } from "../../images/icons/Icons";
import Video from "../../components/media/video";

export interface Features {
  label: string | React.ReactNode;
  section?: string;
  icon: IconInterface;
  sublabel?: string | React.ReactNode;
}

const features: Array<Features> = [
  {
    label: "fully-equipped kitchen",
    section: "two",
    icon: OvenIcon,
  },
  {
    label: (
      <>
        sleeping
        <br />
        pods
      </>
    ),
    section: "two",
    icon: SleepIcon,
  },
  {
    label: "smart technology",
    section: "two",
    icon: SmartFingerIcon,
  },
  {
    label: "sustainable",
    section: "two",
    icon: BulbIcon,
  },
];

interface InfoSectionProps {
  content: Array<PageElement>;
}

const InfoSection: React.FC<InfoSectionProps> = ({ content }) => {
  const tl1 = React.useRef<GSAPTimeline>();
  const tl2 = React.useRef<GSAPTimeline>();
  const firstWrapper = React.useRef<HTMLDivElement>(null);
  const secondWrapper = React.useRef<HTMLDivElement>(null);
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    const firstChildrenIndexSection = [0, 1, 2, 3, 4];
    const firstChildrenIndexSectionMobile = [0, 1, 2];
    const firstIndexSection = matchMobile ? firstChildrenIndexSectionMobile : firstChildrenIndexSection;
    const secondChildrenIndexSection = [1, 0, 2, 3];
    const secondChildrenIndexSectionMobile = [0, 1, 2];
    const secondIndexSection = matchMobile ? secondChildrenIndexSectionMobile : secondChildrenIndexSection;

    tl1.current = onScrollAnimationDesktop(firstWrapper.current, returnRefArray(firstWrapper.current, firstIndexSection), matchMobile);
    tl2.current = onScrollAnimationDesktop(secondWrapper.current, returnRefArray(secondWrapper.current, secondIndexSection, [2]), matchMobile);

    return () => {
      tl1?.current?.kill();
      tl2?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <>
      <FirstMediaWrapper ref={firstWrapper}>
        <MediaCell1>
          <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[0]) }} />
        </MediaCell1>
        <MediaCell2>
          <PimcoreImage image={getElementImage(content[1])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell2>
        <MediaCell3>
          <ParagraphWrapper>
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[2]) }} />
          </ParagraphWrapper>
        </MediaCell3>
        <MediaCell4>
          <ImageLeftWrapper>
            <PimcoreImage image={getElementImage(content[3])} modifier="w-full rounded-lg" withAspectRatio />
          </ImageLeftWrapper>
        </MediaCell4>
        <MediaCell5>
          <ImageRightWrapper>
            <PimcoreImage image={getElementImage(content[4])} modifier="w-full rounded-lg" withAspectRatio />
          </ImageRightWrapper>
        </MediaCell5>
      </FirstMediaWrapper>
      <SecondMediaWrapper ref={secondWrapper}>
        <MediaCell6>
          <HeadlineWrapper>
            <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[5]) }} />
          </HeadlineWrapper>
        </MediaCell6>
        <MediaCell7>
          <Video placeholder={getElementImage(content[6])} video={getElementVideo(content[6])} />
        </MediaCell7>
        <MediaCell8>
          <ParagraphSecondWrapper>
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[7]) }} />
          </ParagraphSecondWrapper>
          <IcoWraper>
            {features.map((feature, index) => (
              <IcoBox key={`benefit3-${index}`} label={feature.label} section={feature.section ? feature.section : ""}>
                <IcoImg src={feature.icon.src} alt={feature.icon.alt} />
              </IcoBox>
            ))}
          </IcoWraper>
        </MediaCell8>
        <MediaCell9>
          <PimcoreImage image={getElementImage(content[8])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell9>
      </SecondMediaWrapper>
    </>
  );
};

export default InfoSection;

const Wrapper = styled.div.attrs<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>({
  className: "tablet:grid tablet:grid-cols-12 tablet:gap-4 px-5 tablet:grid-flow-col",
})<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>``;

const IcoImg = styled.img.attrs({ className: "min-w-full h-full" })``;

const FirstMediaWrapper = styled(Wrapper).attrs({
  className: "tablet:grid tablet:grid-cols-12 tablet:gap-4 px-5 tablet:grid-flow-col scrollspy mb-14 tablet:mb-36",
})``;

const IcoWraper = styled.div.attrs({ className: "flex flex-wrap justify-between tablet:pr-36" })``;

const HeadlineWrapper = styled.div.attrs({})``;

const ParagraphWrapper = styled.div.attrs({ className: "tablet:mb-44 tablet:pr-6 " })``;

const ParagraphSecondWrapper = styled.div.attrs({ className: "mb-8 tablet:mb-16 tablet:pr-32" })``;

const MediaCell1 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-3 row-start-1",
})``;

const MediaCell2 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-5 tablet:row-start-1 tablet:row-span-3 mb-8 tablet:mb-0",
})``;

const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 mb-14 tablet:mb-0",
})``;

const MediaCell4 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-5 tablet:row-start-3 tablet:row-span-3 hidden tablet:block relative",
})``;

const MediaCell5 = styled.div.attrs({
  className: "tablet:col-start-3 tablet:col-span-4 tablet:row-start-4 tablet:row-span-2 hidden tablet:block",
})``;

const SecondMediaWrapper = styled(Wrapper).attrs({ className: "tablet:mb-36 scrollspy" })`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(4, auto);
  }
`;

const MediaCell6 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-1 tablet:row-span-1",
})``;

const MediaCell7 = styled.div.attrs({
  className: "tablet:row-start-1 tablet:row-span-2 tablet:col-start-7 tablet:col-span-6 mb-8 tablet:mb-0",
})``;

const MediaCell8 = styled.div.attrs({
  className: "tablet:row-start-2 tablet:row-span-4 tablet:col-start-2 tablet:col-span-5",
})``;

const MediaCell9 = styled.div.attrs({
  className: "tablet:row-start-3 tablet:row-span-2 tablet:col-start-6 tablet:col-span-5 hidden tablet:block",
})``;
